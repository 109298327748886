.preloader {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.preloader__spinner {
  display: block;
  width: 74px;
  height: 74px;
  margin-bottom: 24px;
  border: 1px solid #1a1b22;
  border-bottom-color: #d1d2d6;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
}
.preloader__text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
