.footer {
  font-family: "Roboto", sans-serif;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 7.2%;
  justify-content: space-between;
}
.footer__text {
  font-size: 16px;
  line-height: 22px;
  color: #b6bcbf;
}
.footer__navigation {
  display: flex;
  align-items: center;
}
.footer__link {
  text-decoration: none;
  color: #1a1b22;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.footer__link:hover {
  opacity: 0.6;
}
.footer__link-social {
  width: 20px;
  height: 20px;
}
.footer__navigation {
  display: flex;
  width: 55%;
  justify-content: flex-end;
}
.footer__text-link-box {
  display: flex;
  justify-content: space-between;
  max-width: 304px;
  width: 100%;
  margin-right: 40px;
}
.footer__social-box {
  width: 66px;
  display: flex;
  justify-content: space-between;
}
.footer__link-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0 40px;
  }
  .footer__text-link-box {
    margin-right: 26px;
    max-width: 264px;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    height: 176px;
    padding: 20px 16px 22px 16px;
    flex-direction: column;
  }
  .footer__link-box {
    justify-content: space-between;
  }
  .footer__text {
    max-width: 288px;
    width: 100%;
    order: 3;
  }
  .footer__navigation {
    width: 100%;
  }
  .footer__text-link-box {
    flex-direction: column;
    max-width: 200px;
    margin: 0;
    height: 72px;
    justify-content: space-between;
  }
}
