.news-card {
  max-height: 576px;
  height: 100%;
  max-width: 32.4%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  color: #1a1b22;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: none;
  transition: box-shadow 0.2s linear;
  cursor: pointer;
  margin-bottom: 16px;
}
.news-card:hover {
  box-shadow: 0px 20px 50px 10px rgba(8, 29, 45, 0.1);
}
.news-card__button {
  width: 40px;
  right: 6%;
}
.news-card__flag {
  opacity: 0.4;
}
.news-card__button:hover .news-card__flag {
  opacity: 1;
}
.news-card__flag_marked:hover {
  opacity: 0.8;
}
.news-card__image {
  max-width: 100%;
  height: 272px;
  object-fit: cover;
  object-position: center;
}
.news-card__info {
  background-color: #fff;
  padding: 20px 24px 24px 24px;
}
.news-card__date {
  font-family: 'Source Sans Pro', sans-serif;
  color: #b6bcbf;
  font-size: 18px;
  line-height: 24px;
}
.news-card__title {
  font-size: 26px;
  line-height: 30px;
  margin: 12px 0 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-card__text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 18px 0;
  min-height: 110px;
  max-width: 352px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.news-card__source {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #b6bcbf;
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.button-style {
  border: none;
  height: 40px;
  border-radius: 8px;
  position: absolute;
  top: 4.2%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: inherit;
  background-color: #fff;
}
.news-card__reminder {
  display: none;
  position: absolute;
  width: 182px;
  height: 40px;
  font-size: 12px;
  line-height: 14px;
  background-color: #fff;
  right: calc(40px + 8%);
  cursor: default;
}
.news-card__keyword {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  position: absolute;
  font-size: 14px;
  line-height: 24px;
  padding: 0 22px;
  left: 6%;
}
.news-card__trash {
  opacity: 0.4;
}
.news-card__button:hover .news-card__trash {
  opacity: 1;
}
.news-card__button_disabled:hover + .news-card__reminder {
  display: flex;
}

@media screen and (max-width: 900px) {
  .news-card__reminder {
    right: 25%;
    width: 72%;
  }
}
@media screen and (max-width: 768px) {
  .news-card {
    max-width: 224px;
    min-width: 200px;
    width: 100%;
    max-height: 430px;
  }
  .news-card__button {
    right: 3.5%;
    top: 2%;
  }
  .news-card__info {
    padding: 16px;
  }
  .news-card__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .news-card__text {
    margin-bottom: 10px;
  }
  .button-style {
    top: 2%;
  }
  .news-card__keyword {
    left: 3.5%;
  }
}

@media screen and (max-width: 600px) {
  .news-card {
    margin: 0 auto 15px auto;
    max-width: 288px;
    max-height: 440px;
  }
  .news-card:last-of-type {
    margin-bottom: 0;
  }
  .news-card__image {
    max-height: 196px;
  }
  .news-card__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .news-card__text {
    -webkit-line-clamp: 3;
    height: 66px;
    min-height: auto;
  }
}
@media screen and (max-width: 320px) {
  .news-card {
    max-width: 100%;
    width: 100%;
    margin: 0 0 10px 0;
  }
}
