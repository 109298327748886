.form {
  padding: 34px 36px 28px 36px;
  background-color: #fff;
  border-radius: 16px;
  outline: none;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #1a1b22;
  font-family: "Roboto", sans-serif;
}
.form__close-button {
  position: absolute;
  background-color: transparent;
  top: -45px;
  right: -45px;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s linear;
}
.form__close-button:hover {
  opacity: 0.6;
}
.form__title {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  margin-bottom: 9px;
}
.form__error {
  font-family: "Inter", sans-serif;
  margin: 17px 0 8px 0;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  text-align: center;
}
.form__submit-button {
  border: none;
  outline: none;
  width: 100%;
  background-color: #2f71e5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  color: #fff;
  padding: 20px 0;
  transition: background-color 0.2s linear;
  margin-bottom: 16px;
  cursor: pointer;
}
.form__submit-button:hover {
  background-color: #347eff;
}
.form__submit-button:active {
  background-color: #2a65cc;
}
.form__submit-button_disabled {
  color: #b6bcbf;
  background-color: #e6e8eb;
  box-shadow: none;
}
.form__submit-button_disabled:hover {
  color: #b6bcbf;
  background-color: #e6e8eb;
  cursor: default;
}
.form__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
}
.form__button-secondary {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  color: #2f71e5;
  transition: opacity 0.2s linear;
}
.form__button-secondary:hover {
  opacity: 0.6;
}
@media screen and (max-width: 600px) {
  .form {
    width: 100%;
    margin-top: 56px;
    height: calc(100% - 56px);
    padding: 16px;
    border-radius: 16px 16px 0 0;
    box-sizing: border-box;
    z-index: 7;
  }
  .form__close-button {
    width: 24px;
    height: 24px;
    right: 20px;
    top: -40px;
  }
  .form__close-icon {
    width: 24px;
    height: 24px;
  }
}
