.search-form {
  display: flex;
  border-radius: 100px;
  width: 100%;
  height: 64px;
  position: relative;
}
.search-form__input {
  font-family: "Roboto", sans-serif;
  padding-left: 24px;
  border-radius: 100px;
  outline: none;
  width: 85%;
  border: none;
  border: 1px solid #d1d2d6;
  backdrop-filter: blur(8px);
}
.search-form__input::placeholder {
  color: #b6bcbf;
}
.search-form__button {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  background-color: #2f71e5;
  border-radius: 100px;
  width: 168px;
  position: absolute;
  height: 100%;
  border: none;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.search-form__button:hover {
  background-color: #347eff;
}
.search-form__button:active {
  background-color: #2a65cc;
}
@media screen and (max-width: 768px) {
  .search-form {
    height: 56px;
  }
}

@media screen and (max-width: 600px) {
  .search-form {
    height: 128px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .search-form__input {
    height: 56px;
    width: 100%;
  }
  .search-form__button {
    position: static;
    height: 56px;
    width: 100%;
    margin-top: 16px;
  }
}
