.news-card-list {
  display: none;
  color: #1a1b22;
  flex-direction: column;
  padding: 80px 7.2%;
  background-color: #f5f6f7;
  position: relative;
}
.news-card-list_opened {
  display: flex;
}
.news-card-list__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0 16px 0;
}
.news-card-list__cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.1%;
  justify-content: center;
}
.news-card-list__title {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 40px;
  line-height: 46px;
  margin: 0;
  font-weight: 400;
}
.news-card-list__button {
  display: none;
  border-radius: 80px;
  max-width: 288px;
  width: 100%;
  height: 64px;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  align-self: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s linear;
}
.news-card-list__button_active {
  display: block;
}

.news-card-list__button:hover {
  background-color: #e8e8e8;
}

@media screen and (max-width: 768px) {
  .news-card-list {
    padding: 40px;
  }
  .news-card-list__container {
    gap: 8px;
  }
  .news-card-list__title {
    font-size: 30px;
    line-height: 34px;
  }
  .news-card-list__button {
    max-width: 240px;
    height: 56px;
  }
}

@media screen and (max-width: 600px) {
  .news-card-list {
    padding: 32px 16px;
  }
  .news-card-list__container {
    margin: 58px 0 21px 0;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .news-card-list__button {
    max-width: 100%;
  }
}
