.about {
  display: flex;
  padding: 80px 7.2%;
  color: #1a1b22;
}
.about__image {
  max-width: 464px;
  width: 40%;
  height: auto;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
}
.about__text-container {
  padding: 50px 0 0 56px;
  max-width: 600px;
}
.about__title {
  font-family: "Roboto Slav", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  margin: 0;
}
.about__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 24px 0 0 0;
}

@media screen and (max-width: 768px) {
  .about {
    padding: 40px 40px 61px 40px;
  }
  .about__text-container {
    padding: 0 0 0 32px;
  }
  .about__title {
    font-size: 30px;
    line-height: 40px;
  }
  .about__text {
    margin-top: 16px;
  }
  .about__text:nth-last-child(1) {
    margin-top: 30px;
  }
  .about__image {
    margin-top: 20px;
    max-width: 232px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about {
    flex-direction: column;
    padding: 32px 16px 28px 16px;
    margin-top: 100px;
  }
  .about__text-container {
    padding: 0;
  }
  .about__title {
    margin: 24px 0 16px 0;
  }
  .about__image {
    margin: 0 auto;
    max-width: fit-content;
    width: 272px;
    height: 272px;
  }
}
