.sign-button {
  height: 48px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer;
  min-width: 175px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.sign-button_color_black {
  color: #000;
  border: 1px solid #d1d2d6;
}
.sign-button_color_black:hover {
  border: 1px solid #000;
}
.sign-button__icon {
  margin-left: 15px;
}
@media screen and (max-width: 600px) {
  .sign-button {
    width: 100%;
  }
}
