.app {
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.app-background {
  background-image: url(../../images/background.png);
  background-repeat: no-repeat;
  background-position: top center;
}
